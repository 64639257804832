import React from "react"
import Seo from "../components/molecules/Seo"
import Splash from "../components/organisms/Splash"
import ContentHolder from "../components/organisms/ContentHolder"
import Image from "../components/atoms/Image"
import Video from "../components/atoms/Video"
import vid from "../../static/videos/ttc-screen-capture.mp4"
import WorkTextBlock from "../components/molecules/WorkTextBlock"
import RelatedWork from "../components/organisms/RelatedWork"
import WorkImage from "../components/molecules/WorkImage"
import ArrowText from "../components/atoms/ArrowText"
import WorkMultipleImage from "../components/molecules/WorkMultipleImage"
import Numbers from "../components/organisms/Numbers"
import Number from "../components/molecules/Number"
import thumb from "../../static/images/facebook-2A.png"

function FaceBook() {
  return (
    <>
      <Seo
        title="Creating a social media movement | Craig Walker x Facebook"
        description="Craig Walker designed and developed an editorial approach for the TTC Labs website, synthesising and curating their comprehensive repository of resources into four interactive stories."
        image={thumb}
      />
      <Splash
        text={
          <>
            Creating content <br />
            and a social media&nbsp;movement
          </>
        }
        work={true}
      >
        <p>
          <b>
            With 4.54 billion people worldwide now using the Internet,
            generating 2.5 quintillion (that’s 18 zeros) of data every day,
            privacy and data protection is a growing and major focus for
            governments, businesses and individuals.
          </b>
        </p>
        <p>
          TTC Labs, a global cross-industry initiative backed by Facebook,
          brings together major businesses, government organisations and
          academic institutions with the aim of putting people in control of
          their privacy.
        </p>
        <p>
          TTC Labs needed a way to share the wealth of information and
          perspectives that they generate and capture. While rich in content,
          the collection of reports and articles on their website was difficult
          for policy makers, designers and privacy advocates to navigate and
          engage with.
        </p>
        <p>
          Craig Walker designed and developed an editorial approach for the TTC
          Labs website, synthesising and curating their comprehensive repository
          of resources into four interactive stories. The goal was to help users
          browse and understand topics quickly and find clear entry points into
          the areas that interest them most.
        </p>
        <ArrowText>
          <p>Learn More</p>
        </ArrowText>
      </Splash>

      <ContentHolder>
        <WorkImage transparent>
          <Image imgName="facebook-2A.png" />
        </WorkImage>

        <Numbers>
          <Number number={`100+`} text="Articles catalogued and tagged" />
          <Number number={120} text="Social media posts generated" />
          <Number number={5} text="Interactive stories created" />
        </Numbers>

        <WorkImage fullWidth>
          <Image imgName="transparency-1.jpg" />
        </WorkImage>

        <WorkTextBlock>
          <h2>Challenging stories, beautifully&nbsp;told</h2>
          <p>
            Working across desktop and mobile, we took a bold editorial
            approach, bringing to life complex topics through a mixture of
            storytelling, illustration and editorial web design.
          </p>
          <p>
            With the website’s content curated and articulated, TTC Labs asked
            us to develop a social media strategy to help them reach and engage
            key audiences in policy and design.
          </p>
        </WorkTextBlock>

        <WorkImage>
          <Video videoType="video/mp4" videoSrc={vid} />
        </WorkImage>

        <WorkTextBlock>
          <h2>Growing the movement&nbsp;organically</h2>
          <p>
            We took a targeted approach, generating a plan with bespoke content
            for LinkedIn, Instagram and Facebook. We tailored the content to the
            medium, choosing the topics and tone that would speak naturally to
            each platform’s audience.
          </p>
        </WorkTextBlock>

        <WorkMultipleImage
          images={[
            "ttc-new-00002.jpg",
            "ttc-new-00003.jpg",
            "ttc-new-00004.jpg",
            "ttc-new-00005.jpg",
            "ttc-new-00006.jpg",
            "ttc-new-00007.jpg",
          ]}
        />
        <WorkMultipleImage
          images={[
            "ttc-new-00008.jpg",
            "ttc-new-00009.jpg",
            "ttc-new-00010.jpg",
          ]}
        />
        <WorkMultipleImage
          images={[
            "ttc-new-00011.jpg",
            "ttc-new-00012.jpg",
            "ttc-new-00013.jpg",
            "ttc-new-00014.jpg",
          ]}
        />

        <RelatedWork exclude="facebook" />
      </ContentHolder>
    </>
  )
}

export default FaceBook
